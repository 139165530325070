import { enableMapSet } from "immer";
import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";

import data from "@emoji-mart/data";
import { init } from "emoji-mart";

import { toast } from "react-hot-toast";
import { BootRipple } from "~/components/BootRipple";
import { sentryInit } from "~/utils/sentry";

sentryInit();

void init({ data });

const App = lazy(() => import("./App").then((m) => ({ default: m.App })));

enableMapSet();

const rootElement = document.getElementById("root");

if (!rootElement) throw new Error("Failed to find root element '#root'");

createRoot(rootElement).render(
  <Suspense fallback={<BootRipple />}>
    <App />
  </Suspense>,
);

import.meta.hot?.on("vite:beforeUpdate", () => {
  toast.dismiss();
});
